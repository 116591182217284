<template>
  <div class='meetdetail'  >
      <item-scroll>
          <div class='infocontainer' >
              <div class='infocontent'>
                  <div class='infocontentblock'>
                      <div  class='infomation'>
                          <span class='infokey'>会议编号</span>
                          <span class='infovalue'>{{meetingInfo.meeting_number}}</span>
                      </div>
                      <div  class='infomation'>
                          <span class='infokey'>会议名称</span>
                          <span class='infovalue'>{{meetingInfo.meeting_name}}</span>
                      </div>
                  </div>
                  <div class='infocontentblock'>
                      <div  class='infomation'>
                          <span class='infokey'>会议日期</span>
                          <span class='infovalue'>{{$tools.getDate(meetingInfo.start_time)}}</span>
                      </div>
                      <div  class='infomation'>
                          <span class='infokey'>持续时间</span>
                          <span class='infovalue'>{{getTime()}}</span>
                      </div>
                  </div>
                  <div class='infocontentblock'>
                      <div  class='infomation'>
                          <span class='infokey'>主持专家</span>
                          <span class='infovalue'>{{doctorInfo.name}}</span>
                      </div>
                      <div  class='infomation'>
                          <span class='infokey'>所在省份</span>
                          <span class='infovalue'>{{doctorInfo.province}} {{doctorInfo.city}}</span>
                      </div>
                      <div  class='infomation'>
                          <span class='infokey'>会议医院</span>
                          <span class='infovalue'>{{doctorInfo.hospital}}</span>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <div class='meetinfodetail'> 
              <div class='meetinfotitle'>
                  <div class='infomation'>
                      <span class='infokey '>会议讲题</span>
                      <span class='infovalue meetquestionname'>{{meetingInfo.show_title}}</span>
                  </div>
                  <div class='infomation'>
                      <span class='infokey'>讲者姓名</span>
                      <span class='infovalue meetquestionname'>{{doctorInfo.name}}</span>
                  </div>
              </div>
              <div class='meetinfocontent'>
                  <div class='infomation'>
                      <span class='infokey'>主持专家<br/>介绍</span>
                      <span class='infovalue persioninfo'>
                          <img :src="doctorInfo.avatar" alt="">
                          <span class='meetinfoname'>{{doctorInfo.name}}</span>
                          <div class='meetinfodescription'>{{doctorInfo.introduction}}</div>
                      </span>
                  </div>
              </div>
              <div class='meetinfocontent'>
                  <div class='infomation'>
                      <span class='infokey'>讲者介绍</span>
                      <span class='infovalue persioninfo'>
                          <img :src="meetingInfo.speaker_avatar" alt="">
                          <span class='meetinfoname'>{{meetingInfo.speaker_name}}</span>
                          <div class='meetinfodescription' v-html='meetingInfo.speaker_description'></div>
                      </span>
                  </div>
              </div>
          </div> -->
          <div class='meetdetailbtn' >
              <div v-if='meetingInfo.status !== 2' >
                  <div class='meetshareinfo' v-if='showTips' >
                      <div   @click=copy(0)>观众链接分享</div>
                      <div   @click=copy(1)>主持专家链接分享</div>
                  </div>
                  <el-button type="primary" icon="icon-fenxiang" @click='share()'>分享</el-button>
              </div>
              <div v-if='meetingInfo.status == 0' class='meetalterbtn'>
                  <el-button type="primary"  @click='alterInfo()' round>
                      <span></span>
                      <span>修改</span>
                  </el-button>
              </div>
          </div>
        </item-scroll>

  </div>
</template>
<script>
import useClipboard from 'vue-clipboard3'
import ItemScroll from '@/components/unit/ItemScroll'
const cliboard = useClipboard().toClipboard
export default {    
  name:'meetdetail',
  data:()=>{
      return {
        auth:["观众","主持专家"],
        showTips:false,
        meetingInfo:{},
        doctorInfo:{},

      }
  },
  components:{
    ItemScroll
  },
  created(){
      this.loadData();
      console.log(this.$store.state.common)
      this.doctorInfo = this.$store.state.common.userInfo
      document.getElementsByTagName('title')[0].innerHTML = '会议详情'
  },
  mounted(){
  },
  methods:{
      loadData(){
          let url = this.$tools.getURL(this.$urls.admin.meeting,{meeting_id:this.$route.query.meetId})
          this.$axios.get(url).then((res)=>{
              this.meetingInfo = {...this.meetingInfo,...res.data};
          })
      },
      getTime(){
        if(!this.meetingInfo.start_time) return ""
        let startTime = this.$tools.getDate(this.meetingInfo.start_time,"hh:mm")
        let endTime = this.$tools.getDate(this.meetingInfo.endTime,"hh:mm")
        return `${startTime}-${endTime}`
      },
      share(e){
          this.showTips = !this.showTips;
      },
      alterInfo(){
          if(this.meetingInfo.status == 1){
              this.$tips.error({text:'会议已在进行中'})
              return;
          }
          if(this.meetingInfo.status == 2){
              this.$tips.error({text:'会议已结束'})
              return;
          }
          console.log(this.doctorInfo)
          this.$router.push({ 
            path: '/meetinfo', 
            query: {
              meetId:this.meetingInfo.id,
              id:this.doctorInfo.id
            } 
          })
      },
      getMsg(type){ 
        return `${this.auth[type]}链接：${this.meetingInfo.meeting_url}?role=${type}`
      },
      async copy(type){
        try{
          console.log(cliboard)
          await cliboard(this.getMsg(type))
          this.$tips.success({text:'分享信息已经成功复制,请去分享吧'})
          console.log(type,"分享成功")
          this.showTips = !this.showTips;
        }catch(err){
          this.$tips.success({text:'复制失败，请刷新重试'})
          console.log(err,"分享失败")
          this.showTips = false;
        }
      }
  }
}
</script>
<style >
.meetdetail{
  /* padding: 3.2vw 4vw 0 4vw; */
  font-size: 4vw;
  letter-spacing: .2vw;
  box-sizing: border-box;
  height:100%;
  overflow: hidden;
}
.meetdetail .meetCont{
  border-bottom: 1px solid #e6e6e6;
  /* padding-bottom: 2.64vw; */
  position: relative;
}
.meetdetail .infocontainer {
  padding: 8vw 4vw 0 4vw;
  position: relative;
  font-weight: 500;
  background:#ececec;
}
.meetdetail .infocontentblock {
  padding-bottom:5.6vw;
}
.meetdetail .meetinfodetail{
  padding:2.8vw 4vw 0 ;
  border-bottom:1px solid #ddd;
}
.meetdetail .meetinfotitle{
  border-bottom: 1px solid #ddd;
}
.meetdetail .meetquestionname{
  font-size:3.6vw;
  padding-top:.3vw;
}
.meetdetail .meetinfocontent{
  border-bottom:1px solid #ddd;
  padding: 4.4vw 0;
}
.meetdetail .meetinfoname{
  height:10.8vw;
  line-height: 10.8vw;
  font-size:3.6vw;
  font-weight: 500;
}
.meetdetail .meetinfodescription{
  font-size:3.6vw;
  display: inline-block;
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 5vw;
  font-weight: 500;
}
.meetdetail .infomation {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-bottom: 2vw;
}
.meetdetail .infomation span.infokey {
  width: 18vw;
  text-align: left;
  color:#6e6e6e;
  margin-right: 4vw;
}
.meetdetail span.headtitle {
  display: inline-block;
  position: absolute;
  bottom: -3.2vw;;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  background: #81304B;
}
.meetdetail span.infovalue {
  flex:1;
  text-align: left;
}
.meetdetail .persioninfo{
  display: flex;
  flex-direction: column;
}
.meetdetail .persioninfo img{
  width:18vw;
  height:23.6vw;
}

.meetdetail .meetrelated{
  height:17.73vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  /* border-bottom:1px solid #E6E6E6; */
}
.meetdetail .meetrelatedname{
  color:#000;
  /* font-size:3.86vw; */
  font-size:3vw;
  line-height:6.26vw;
}
.meetdetail .meetrelatedocco{
  color:#666;
  font-size:3.33vw;
  line-height:6.26vw;
  font-weight: 400
}

.meetdetail .meetspaceline {
  display:flex;
}
.meetdetail .meetspaceline  span{
  display: inline-block;
  width:6.67vw;
  height:1px;
  background:#ccc;
}
.meetdetail .position{
  padding:6.8vw 0 4.8vw;
  font-weight: 500;
}
.meetdetail .meetdetailbtn{
  padding:0 2.8vw;
  height:20vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetdetail .meetdetailbtn>div{
  width:50%;
  padding:0 2.8vw;
  position: relative;
}
.meetdetail .meetdetailbtn>div>button{
  width:100%;
  border-radius: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetdetail .meetdetailbtn>div:last-child button>span>span:first-child{
  background:url(../../../assets/images/meetopera.png);
  background-size:100% 100%;
  display: inline-block;
  height:4.26vw;
  width:4.26vw;
}
.meetdetail .meetdetailbtn button i{
  display: inline-block;
  font-size:4.26vw !important;
}
.meetdetail .meetalterbtn button{
  background:#9aad2a !important;
  border-color:#9aad2a !important;
}
.meetdetail .meetdetailbtn button  i {
  display: inline-block;
  margin-right:1vw;
}
.meetdetail .meetdetailbtn button  span, .meetdetail .meetdetailbtn button  i {
  vertical-align: middle;
}
.meetdetail .meetshareinfo{
  position: absolute;
  top:-28.4vw;
  height:24.8vw;
  width:calc(100% - 5.6vw);
  background:#ececec;
  border-radius:2vw;
  padding: 3.2vw 4.4vw;
  display: flex;
  flex-direction: column;
  font-size:3.6vw;
  font-weight: 500;
}
.meetdetail .meetshareinfo:after{
  content:'';
  position: absolute;
  left: calc(50% - 4vw);
  bottom:-7vw;
  display: inline-block;
  border-width:4vw;
  border-style: solid;
  border-top-color: #ececec;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.meetdetail .meetshareinfo div:first-child{
  border-bottom:1px solid #ddd;
}
.meetdetail .meetshareinfo div{
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.meetdetail .copyvalue{
  position:absolute;
  top:100000vw;
  right:10000vw
}
</style>